/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/MapProjects.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      // if ('serviceWorker' in navigator) {
      //   console.log('Registering ServiceWorker...');
      //   window.addEventListener('load', function() {
      //       navigator.serviceWorker.register('/sw.js', {
      //           scope: "/"
      //       }).then(function(registration) {
      //           // Registration was successful
      //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //           // Trim the caches on load
      //           navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
      //               command: "trimCaches"
      //           });
      //      }).catch(function(err) {
      //           // registration failed :(
      //           console.log('ServiceWorker registration failed: ', err);
      //       });
      //   });
      // }

      // PreRender for fast website
      function prerenderlink(e) {
        console.log(e, e.currentTarget, e.currentTarget.href);
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('#page-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });

      if (document.getElementById('contact-map')) {
        setTimeout(function() {
          initMap();
          function initMap() {
              // Basic options for a simple Google Map
              // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
              var mapOptions = {
                  // How zoomed in you want the map to start at (always required)
                  zoom: 8,

                  // The latitude and longitude to center the map (always required)
                  center: new google.maps.LatLng(45.398470, -72.723110),

                  // How you would like to style the map.
                  // This is where you would paste any style found on Snazzy Maps.
                  styles: [{featureType:"all",elementType:"labels.text.fill",stylers:[{saturation:36},{color:"#000000"},{lightness:40}]},{featureType:"all",elementType:"labels.text.stroke",stylers:[{visibility:"on"},{color:"#000000"},{lightness:16}]},{featureType:"all",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"administrative",elementType:"geometry.fill",stylers:[{color:"#000000"},{lightness:20}]},{featureType:"administrative",elementType:"geometry.stroke",stylers:[{color:"#000000"},{lightness:17},{weight:1.2}]},{featureType:"landscape",elementType:"geometry",stylers:[{color:"#000000"},{lightness:20}]},{featureType:"poi",elementType:"geometry",stylers:[{color:"#000000"},{lightness:21}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#000000"},{lightness:17}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#000000"},{lightness:29},{weight:.2}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{color:"#000000"},{lightness:18}]},{featureType:"road.local",elementType:"geometry",stylers:[{color:"#000000"},{lightness:16}]},{featureType:"transit",elementType:"geometry",stylers:[{color:"#000000"},{lightness:19}]},{featureType:"water",elementType:"geometry",stylers:[{color:"#000000"},{lightness:17}]}]
              };

              // Get the HTML DOM element that will contain your map
              // We are using a div with id="map" seen below in the <body>
              var mapElement = document.getElementById('contact-map');

              // Create the Google Map using our element and options defined above
              var map = new google.maps.Map(mapElement, mapOptions);

              var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(45.390330, -71.935930),
                  map: map,
                  title: 'Sherbrooke',
                  icon: '/img/map-pointer-red.svg'
              });

              var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(45.430280, -73.453010),
                  map: map,
                  title: 'Brossard',
                  icon: '/img/map-pointer-red.svg'
              });
          }
        }, 2755);
      }

      setTimeout(function() {

        console.log('Start of Anims();');

        document.querySelector('body').classList.add('dom-loaded', 'is-animated');

        const scroll = new LocomotiveScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          offset: [0, 0],
          repeat: false,
          smoothMobile: false,
          getDirection: true
        });

        var refresher = null;

        scroll.on('scroll', (instance) => {
          document.documentElement.setAttribute('data-direction', instance.direction);

          if (instance.delta.y >= 50) {
            document.querySelector('.main-header').classList.add('fixed');
          } else {
            document.querySelector('.main-header').classList.remove('fixed');
          }

          if (instance.delta.y >= 300) {
            document.querySelector('body').classList.add('hide-both-nav');
          } else {
            document.querySelector('body').classList.remove('hide-both-nav');
          }

          clearTimeout(refresher);
          refresher = null;

          refresher = setTimeout(function() {
            scroll.update();
          }, 2855);
        });

        document.querySelector('body').classList.add('is-loaded');

        // Smooth Scroll to href component
        const scrollerDataAttr = 'data-scrollto';
        [].slice.call(document.querySelectorAll('['+scrollerDataAttr+']')).forEach(function(elem) {
          elem.addEventListener('click', (e) => {
            e.preventDefault();
            const target = elem.getAttribute('href');
            scroll.scrollTo(target);
          });
        });

        if (document.querySelector('.main-carousel.autoplay')) {
          [].slice.call(document.querySelectorAll('.main-carousel.autoplay')).forEach(function(elem) {
            var flickityElem = new Flickity( elem, {
              'contain': true,
              'draggable': false,
              'prevNextButtons': false,
              'wrapAround': true,
              'pageDots': true,
              'autoPlay': true,
              'imagesLoaded': true,
              'lazyLoad': true
            });

            if (elem.getAttribute('data-control')) {
              document.querySelector('.left[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.previous();
              });
              document.querySelector('.right[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.next();
              });
            }
          });
        }

        if (document.querySelector('.main-carousel.noautoplay')) {
          [].slice.call(document.querySelectorAll('.main-carousel.noautoplay')).forEach(function(elem) {
            var flickityElem = new Flickity( elem, {
              'contain': true,
              'draggable': true,
              'prevNextButtons': false,
              'wrapAround': true,
              'pageDots': false,
              'autoPlay': false,
              'imagesLoaded': true,
              'lazyLoad': true
            });

            if (elem.getAttribute('data-control')) {
              document.querySelector('.left[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.previous();
              });
              document.querySelector('.right[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.next();
              });
            }
          });
        }

        if (document.querySelector('.main-carousel.noautoplay-nowrap')) {
          [].slice.call(document.querySelectorAll('.main-carousel.noautoplay-nowrap')).forEach(function(elem) {
            var flickityElem = new Flickity( elem, {
              'contain': true,
              'draggable': true,
              'prevNextButtons': false,
              'pageDots': false,
              'autoPlay': false,
              'imagesLoaded': true,
              'lazyLoad': true
            });

            if (elem.getAttribute('data-control')) {
              document.querySelector('.left[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.previous();
              });
              document.querySelector('.right[data-control="'+elem.getAttribute('data-control')+'"]').addEventListener('click', function(e) {
                e.preventDefault();
                flickityElem.next();
              });
            }
          });
        }
        
        setTimeout(function() {
          scroll.update();
        }, 1375);
      }, 375);

      if (window.matchMedia('(max-width: 767px)')) {
        window.onscroll = function(ev) {
          if (window.scrollY >= 50) {
            document.querySelector('.main-header').classList.add('fixed');
          } else {
            document.querySelector('.main-header').classList.remove('fixed');
          }
        };

        // Smooth Scroll to href component
        const scrollerDataAttr = 'a[data-scrollto]';
        document.querySelector('body').addEventListener('click', (e) => {
          if (e.target.getAttribute(scrollerDataAttr) !== '') {
            return
          }
          e.preventDefault();
          const $target = document.querySelector(e.target.getAttribute('href'));
          $target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        });
      }
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
